/* Global footer container */
.footer-footer1 {
  background-color: #FFFCF3; /* White background for a clean look */
  color: #333333; /* Dark gray text for readability */
  padding: 40px 20px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

/* Max-width container for centering content */
.footer-max-width {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Footer content */
.footer-content {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.footer-links {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.footer-column3 {
  text-align: center;
}

/* Social Links Title */
.footer-social-link1-title {
  font-size: 1.5rem;
  font-weight: bold;
  font-size: 35px;
  font-family: STIX Two Text;
  font-weight: 600;
  line-height: 1.5;
  color: #2e2826; /* Vibrant orange for title */
}

/* Social Links Section */
.footer-social-links {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-top: 20px;
}

.footer-social-links div {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.footer-social-links div:hover {
  transform: translateY(-5px);
}

.thq-icon-small {
  width: 30px;
  height: 30px;
  transition: fill 0.3s ease-in-out;
}

/* Original colors for icons */
.footer-link14 .thq-icon-small { fill: #3b5998; } /* Facebook */
.footer-link15 .thq-icon-small { fill: #c32aa3; } /* Instagram */
.footer-link16 .thq-icon-small { fill: #1da1f2; } /* X (Twitter) */
.footer-link17 .thq-icon-small { fill: #0077b5; } /* LinkedIn */
.footer-link18 .thq-icon-small { fill: #ff0000; } /* YouTube */

.thq-body-small1 {
  margin-top: 10px;
  color: #333333; /* Dark gray for text */
}

.thq-body-small2 {
  margin-top: 10px;
  color: #333333; /* Dark gray for text */
}

.footer-link14:hover .thq-icon-small,
.footer-link15:hover .thq-icon-small,
.footer-link16:hover .thq-icon-small,
.footer-link17:hover .thq-icon-small,
.footer-link18:hover .thq-icon-small {
  filter: brightness(0.8); /* Slight darkening on hover */
}

/* Footer credits section */
.footer-credits {
  margin-top: 30px;
  text-align: center;
}

.footer-credits .thq-divider-horizontal {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0; /* Light gray for divider */
  margin: 20px 0;
}

.footer-row {
  display: flex;
  flex-direction: column; /* Change to column for better centering */
  align-items: center; /* Center items */
  gap: 10px; /* Add spacing if needed */
}

.footer-row span {
  font-size: 0.875rem;
  color: #888; /* Medium gray for text */
}

/* Footer link styling */
.footer-footer-links3 {
  display: flex;
  gap: 15px;
}

.footer-footer-links3 span {
  font-size: 0.875rem;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.footer-footer-links3 span:hover {
  color: #ff5722; /* Vibrant orange on hover */
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-social-links {
    gap: 15px;
  }

  .footer-row {
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }
}
