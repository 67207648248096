/* ImageModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  overflow: hidden;
  border-radius: 10px; /* Rounded corners */
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.modal-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  width: 100%; /* Adjust the width */
  height: 400px; /* Fixed height */
  object-fit: cover; /* Crop image to fit */
}

.modal-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.modal-nav-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}
