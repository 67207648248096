.contact-contact20 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding: 20px; /* Add padding for better spacing */
}

.contact-max-width {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100%; /* Ensure it takes full width */
}

.contact-section-title {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  max-width: 100%; /* Take full width */
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}

.contact-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}

.contact-text3 {
  text-align: center;
  padding: 0 10px; /* Add padding for better readability */
}

.contact-row {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-wrap: wrap; /* Make items stack on smaller screens */
}

.contact-content2,
.contact-content4,
.contact-content6 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px; /* Add padding for each section */
  background-color: #f9f9f9; /* Optional: Add background for better separation */
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add subtle shadow for a polished look */
  margin-bottom: 15px; /* Add margin for spacing between sections */
}

.contact-contact-info1,
.contact-contact-info2,
.contact-contact-info3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}

.contact-content3,
.contact-content5,
.contact-content7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.contact-text4,
.contact-text6,
.contact-text8 {
  align-self: stretch;
  text-align: center;
  font-weight: bold; /* Make the titles bold */
}

.contact-text5,
.contact-text7,
.contact-text9 {
  text-align: center;
}

.contact-email,
.contact-phone,
.contact-address {
  text-align: center;
  font-size: 14px; /* Smaller font size for mobile */
}

@media (max-width: 767px) {
  .contact-row {
    flex-direction: column; /* Stack columns on top of each other */
    gap: var(--dl-space-space-two-units); /* Reduce gap on mobile */
  }
  .contact-content2,
  .contact-content4,
  .contact-content6 {
    width: 100%; /* Ensure each section takes full width */
  }
  .contact-text4,
  .contact-text6,
  .contact-text8 {
    font-size: 18px; /* Slightly larger titles on mobile for clarity */
  }
  .contact-email,
  .contact-phone,
  .contact-address {
    font-size: 16px; /* Adjust the font size for mobile readability */
  }
}

@media (max-width: 479px) {
  .contact-content2,
  .contact-content4,
  .contact-content6 {
    padding: 10px; /* Reduce padding for smaller devices */
    gap: var(--dl-space-space-unit); /* Tighten the spacing on smaller screens */
  }
  .contact-text3 {
    padding: 0 5px; /* Further reduce padding on very small devices */
  }
  .contact-text4,
  .contact-text6,
  .contact-text8 {
    font-size: 16px; /* Adjust title size for very small screens */
  }
}
