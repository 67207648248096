:root {
  --primary-color: #e7ab72;
  --hover-color: #e9e8e6;
  --mobile-bg: #a2adb8;
  --link-color: white;
  --font-size-large: 1.2em;
  --icon-size-large: 1.5em;
  --icon-size-mobile: 2em;
  --icon-size-hotel: 2.2em; /* Increased size for hotel icon */
  --transition-time: 0.3s;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.navbar-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--primary-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
}

.navbar-navbar-interactive {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* New container for right side elements */
.navbar-right-container {
  display: flex;
  align-items: center;
}

.navbar-desktop-menu {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.navbar-links1 {
  display: flex;
  gap: 20px;
}

.thq-link {
  display: flex;
  align-items: center;
  color: var(--link-color);
  font-size: var(--font-size-large);
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  transition: transform var(--transition-time), box-shadow var(--transition-time), color var(--transition-time);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  border-radius: 10px;
}

.thq-link .svg-inline--fa {
  font-size: var(--icon-size-large);
  margin-right: 8px;
}

.thq-link:hover {
  transform: translateY(-4px) scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  color: var(--hover-color);
}

/* Burger menu styles */
.navbar-burger-menu {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
}

.navbar-burger-menu div {
  width: 30px;
  height: 3px;
  background-color: var(--link-color);
  margin: 3px 0;
  transition: var(--transition-time);
}

.navbar-burger-menu.open div:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.navbar-burger-menu.open div:nth-child(2) {
  opacity: 0;
}

.navbar-burger-menu.open div:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Mobile menu styles */
.navbar-mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  background-color: var(--mobile-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-100%);
  transition: transform var(--transition-time);
}

.navbar-mobile-menu.open {
  transform: translateX(0);
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2em;
  color: var(--link-color);
  cursor: pointer;
}

.navbar-links2 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;
}

.navbar-links2 .thq-link {
  font-size: var(--font-size-large);
}

/* Hotel icon styling */
.navbar-hotel-icon {
  display: none; /* Hide by default */
  color: var(--link-color);
  font-size: var(--icon-size-hotel); /* Increased size for the hotel icon */
  margin-right: 20px; /* Add some spacing for alignment */
}

.navbar-hotel-icon:hover {
  color: var(--hover-color);
  transform: scale(1.1);
  transition: transform var(--transition-time);
}

/* Media queries */
@media (min-width: 769px) {
  .navbar-navbar-interactive {
    justify-content: center; /* Center all items */
  }

  .navbar-desktop-menu {
    display: flex; /* Ensure desktop menu is displayed */
  }

  .navbar-hotel-icon {
    display: none; /* Ensure hotel icon is hidden on desktop */
  }

  .navbar-burger-menu {
    display: none; /* Ensure burger menu is hidden on desktop */
  }

  .navbar-links1 {
    display: flex; /* Keep links in a row */
    justify-content: center; /* Center links */
  }
}

@media (max-width: 768px) {
  .navbar-desktop-menu {
    display: none; /* Hide desktop menu on mobile */
  }

  .navbar-burger-menu {
    display: flex; /* Show burger menu on mobile */
  }

  .navbar-hotel-icon {
    display: block; /* Show hotel icon on mobile */
    margin-left: 10px; /* Spacing to the left of the icon */
  }

  .thq-link .svg-inline--fa {
    font-size: var(--icon-size-mobile); /* Increase icon size on mobile */
  }

  /* New styles for the navbar-burger-menu to position it properly */
  .navbar-burger-menu {
    position: absolute; /* Position absolute to move it freely */
    right: 20px; /* Align to the right */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust to center */
  }
}
