/* Common Styles */
.steps-container1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.steps-max-width {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.steps-section-header h2 {
  font-size: 2.5rem; /* Increased font size */
  margin-bottom: 1rem;
  color: var(--dl-color-text);
}

.steps-section-header p {
  margin: 0.5em 0;
  line-height: 1.6;
  font-size: 1.2rem; /* Increased font size */
  color: var(--dl-color-text);
  text-align: justify;
}

.steps-section-header p:first-of-type {
  font-weight: bold;
}

.steps-section-header {
  gap: var(--dl-space-space-oneandhalfunits);
  top: 10%;
  display: flex;
  position: sticky;
  align-items: flex-start;
  flex-direction: column;
}

/* Divider Styles */
.section-divider {
  width: 100%; /* Full width */
  border: 0; /* Remove default border */
  height: 3px; /* Increased height of the line */
  background-color: var(--dl-color-theme-accent1); /* Color of the line */
  margin-bottom: 1rem; /* Space below the line */
}

/* Image Gallery Styles */
.image-gallery {
  display: flex;
  gap: 1.5rem; /* Increased space between images */
  margin-top: 1.5rem; /* Space above the image gallery */
  flex-wrap: wrap; /* Allow images to wrap onto the next line */
  justify-content: center; /* Center images */
}

/* Image Frame Styles */
.image-frame {
  width: 300px; /* Fixed width for uniformity */
  height: 300px; /* Fixed height for uniformity */
  border: 10px solid #ccc; /* Light gray border */
  border-radius: 10px; /* Rounded corners */
  position: relative; /* For positioning the hanging effect */
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3); /* Enhanced shadow for depth */
  perspective: 1000px; /* Perspective for 3D effect */
  overflow: hidden; /* Hide overflow for a neat frame */
  transition: transform 0.3s ease; /* Transition for hover effect */
}

.image-frame:hover {
  transform: translateY(-15px) rotateY(5deg); /* Lift and slightly rotate on hover */
}

/* Updated Image Styles */
.attraction-image {
  width: 100%; /* Make the images responsive */
  height: 100%; /* Cover the entire frame */
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 10px; /* Adjusted rounded corners for better aesthetics */
  transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition for hover effect */
}

/* Adding a 3D effect to images on hover */
.image-frame:hover .attraction-image {
  transform: scale(1.05) rotate(2deg); /* Slightly enlarge and rotate the image on hover */
  filter: brightness(1.1); /* Increase brightness on hover */
}

/* Tooltip Styles */
.image-tooltip {
  position: absolute;
  bottom: 10px; /* Position above the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center the tooltip */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: white; /* Text color */
  padding: 5px 10px; /* Padding for the tooltip */
  border-radius: 5px; /* Rounded corners */
  visibility: hidden; /* Initially hidden */
  opacity: 0; /* Transparent */
  transition: visibility 0s, opacity 0.2s linear; /* Smooth transition */
}

/* Show tooltip on image frame hover */
.image-frame:hover .image-tooltip {
  visibility: visible; /* Show on hover */
  opacity: 1; /* Fully visible */
}

/* Cards */
.steps-container3 {
  grid-area: span 1/span 1/span 1/span 1;
}

.steps-container4 {
  top: 10%;
  position: sticky;
  transform: rotate(-2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent1);
}

.steps-container5 {
  top: 10%;
  position: sticky;
  transform: rotate(2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent2);
}

.steps-container6 {
  top: 10%;
  position: sticky;
  transform: rotate(-2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent1);
}

.steps-container7 {
  top: 10%;
  position: sticky;
  transform: rotate(2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent2);
}

/* Hide paragraphs and images on mobile */
@media (max-width: 767px) {
  .hidden-on-mobile {
    display: none; /* Hide specific paragraphs and image gallery on smaller screens */
  }
  .image-gallery {
    display: none; /* Hide the image gallery on mobile */
  }
}
