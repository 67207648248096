.features1-container2 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-fiveunits);
  position: relative;
  grid-template-columns: 1fr 1fr;
}

.features1-image-container {
  height: 100%;
  display: flex;
  position: relative;
}

.features1-image {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures images fit well within their container */
  animation-name: fadeIn;
  animation-duration: 300ms;
  animation-timing-function: ease;
}

.features1-arrow-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between; /* Space arrows evenly */
  width: calc(100% - 40px); /* Adjust width for padding */
}

.features1-arrow-left,
.features1-arrow-right {
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #fff; /* Adjust color as needed */
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .features1-arrow-container {
    width: calc(100% - 20px); /* Reduce padding for smaller screens */
  }
  
  .features1-arrow-left,
  .features1-arrow-right {
    font-size: 1.5rem; /* Smaller arrow size for mobile */
  }
}

.features1-tabs-menu {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.features1-tab-horizontal1,
.features1-tab-horizontal2,
.features1-tab-horizontal3 {
  gap: var(--dl-space-space-twounits);
  cursor: pointer;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
}

.features1-divider-container1,
.features1-divider-container2,
.features1-divider-container3 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}

.features1-container3,
.features1-container4,
.features1-container5 {
  width: 2px;
  align-self: stretch;
  background-color: var(--dl-color-theme-neutral-dark);
}

.features1-content1,
.features1-content2,
.features1-content3 {
  gap: 16px;
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 991px) {
  .features1-container2 {
    grid-gap: var(--dl-space-space-twounits);
    grid-template-columns: 1fr;
  }
}
