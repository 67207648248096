.hero-header78 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  margin-top: 45px;
  flex-direction: column;
}

.hero-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}

.hero-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}

.hero-text1 {
  text-align: center;
  color: #e7ab72; /* Change heading color (Desktop) */
}

.hero-text2 {
  text-align: center;
  color: #ffffff; /* Change paragraph color (Desktop) */
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white background */
  backdrop-filter: blur(50px); /* Blur effect */
  border-radius: 10px; /* Rounded corners for a softer look */
  padding: 15px; /* Padding around the text */
}

.hero-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
}

.hero-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}

.hero-row-container1 {
  width: 100%;
  overflow: hidden; /* Prevent overflow */
}

.hero-placeholder-image {
  width: 400px; /* Fixed width for images */
  height: 400px; /* Fixed height for images */
  cursor: pointer; /* Change cursor to pointer for clickable images */
}

.thq-animated-group-horizontal {
  display: flex;
  animation: scroll-x 20s linear infinite; /* Adjusted duration for faster scrolling */
}

@media(max-width: 767px) {
  .hero-header78 {
    background-image: url('../assets/images/back2.jpg');
    background-size: cover; /* Cover the entire area */
    background-position: center; /* Center the background image */
    opacity: 1; /* Adjust opacity as needed */
  }

  .hero-content2 {
    width: 100%; /* Full width on smaller screens */
  }

  /* Change text color for mobile */
  .hero-text1 {
    color: #e7ab72; /* Change heading color (Mobile) */
  }

  .hero-text2 {
    color: #ffffff; /* Change paragraph color (Mobile) */
  }
}

@media(max-width: 479px) {
  .hero-actions {
    width: 100%;
    flex-direction: column; /* Stack actions vertically */
  }

  .hero-button1,
  .hero-button2 {
    width: 100%; /* Full width for buttons */
  }
}
